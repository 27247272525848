<style lang="less" scoped>
.contactUs {
  overflow: auto;
  .-left {
    float: left;
    .msg {
      display: flex;
      align-items: center;
      margin-top: 16px;
      line-height: 40px;
      .label {
        width: 96px;
        height: 154px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-right: 24px;
      }
      .value {
        width: 329px;
        height: 154px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .codes {
      display: flex;
      align-items: center;
      .code {
        text-align: center;
        margin-right: 22px;
        font-size: 14px;
        .code-wrap {
          width: 162px;
          height: 162px;
          background: #ffffff;
          border-radius: 16px;
          overflow: hidden;
          margin-top: 26px;
          margin-bottom: 12px;
          img {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
  .-right {
    float: right;
    width: 384px;
    height: 420px;
    background: #f5f5f5;
    border-radius: 20px;
    border: 1px solid #eeeeee;
    .allmap {
      width: 384px;
      height: 420px;
    }
  }
}
</style>
<template>
  <div class="contactUs">
    <div class="-left">
      <img src="../../assets/img/首页_slices/img_qiaomeng_pic.png" alt="" />
      <div class="msg">
        <div class="label">
          工作时间： <br />联系地址： <br />
        </div>
        <div class="value">
          周一至周五，上午9:00-18:00（节假日除外）<br />
          深圳市南山区西丽街道TCL国际E城G3栋601室<br />
        </div>
      </div>
      <div class="codes">
        <div class="code">
          <div class="code-wrap">
            <img
              src="../../assets/img/首页_slices/img_wechat_fuzeren.jpg"
              alt="小熊负责人微信"
            />
          </div>
          添加负责人微信
        </div>
        <div class="code">
          <div class="code-wrap">
            <img
              src="../../assets/img/首页_slices/img_app_bear.png"
              alt="小熊负责人微信"
            />
          </div>
          进入小熊APP
        </div>
      </div>
    </div>
    <div id="allmap" class="-right"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      map: null,
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  methods: {
    initMap() {
      var map = new BMap.Map("allmap"); // 创建Map实例
      map.centerAndZoom(new BMap.Point(113.928123, 22.568844), 11);
      var local = new BMap.LocalSearch(map, {
        renderOptions: { map: map, panel: "r-result" },
      });
      local.search("国际E城G3-601");
    },
    openSign() {
      window.open("https://wenjuan.feishu.cn/m?t=sd6EeCBhDGpi-izib");
    },
    loadScript(src) {
      return new Promise((res) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = res;
        document.head.appendChild(script);
      });
    },
  },
};
</script>